.cstm-bg {
    /* background: url(http://localhost:3000/assets/img/welcome_background.gif); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* color: #e64e83; */
    /* height: 120px; */
    
}

.cstm-bg-dark {
    background: #bdc3c7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2c3e50, #bdc3c7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 5px;
    display: flex;
    /* height: 90px; */
    /* align-items:center; */
}
