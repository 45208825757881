.dash-serviceBoxTitle {
    font-size: 15px;
    font-weight: bold;
}
.dash-serviceBoxBg {
    /* width: 90px; */
}
.dash-serviceBox {
    /* position: absolute; */
    text-align: center;
    margin: 15px 0 5px 0;
}
.dash-serviceBox .service-image > a > img {
    width: 90px;
    height: auto;
    /* border-radius: 50%; */
    /* transition: all 0.2s ease-in-out 0s; */
    /* border: 1px solid black; */
    /* background-color: #e2e1e0; */
}
.dash-service-content {
    padding-top: 5px;
}
.dash-serviceBox .service-content h3 {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1.25px;
    margin-bottom: 16px;
    color: #373e44;
    transition: all 0.21s ease 0s;
}
.dash-serviceBox .service-content p {
    padding: 0 35px;
    color: #616161;
    line-height: 1.7;
}
.dash-serviceBox .service-content h6 {
    padding-top: 5px;
    color: #ffffff;
}
.dash-serviceBox .read {
    margin-top: 20px;
}
.dash-serviceBox .read > a {
    text-transform: uppercase;
    color: #dc005a;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    position: relative;
    transition: all 0.16s ease 0s;
}
.dash-serviceBox .read > a:hover:after {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 8px;
    position: absolute;
    right: -13px;
    top: 0px;
}
.dash-serviceBox:hover .service-image > a > img {
    padding: 8px;
    border: 3px solid #7a4b94;
    transition: all 0.2s ease-in-out 0s;
}
.dash-serviceBox:hover .dash-service-content h3,
.dash-serviceBox:hover .read > a {
    color: #7a4b94;
}
